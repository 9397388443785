/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import * as styles from "./../components/BlogUiComponents/BlogsMain/BlogsMain.module.scss";
import * as contactstyle from "./../components/Shared/ContactUiComponents/ContactForm.module.scss";
import * as contactinputstyle from "./../components/Shared/ContactUiComponents/ContactFormInput/ContactFormInput.module.scss";
import InnerWrapper from "../components/Shared/InnerWrapper/InnerWrapper";
import BlogArticleImage from "../components/HomeUiComponents/BlogMain/BlogArticle/BlogArticleImage";
import BlogArticle from "../components/HomeUiComponents/BlogMain/BlogArticle/BlogArticle";
import CircleGreenBlur from "../components/Shared/CircleOutlineAndBlur/Circlegreenblur";
import CircleBlur from "../components/Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../components/Shared/CircleOutlineAndBlur/CircleOutline";
import { SEO } from "../components/seo";
import tableDesktop from "../images/search-main.jpg";
import { UseWindowSize } from "./../components/hooks/UseWindowSize";
import Sticky from "react-sticky-el";
import translate from "../i18n/translate";

const Search = ({ data }) => {
  const [state, setState] = useState({
    filteredPosts: [],
    query: "",
  });
  const { width } = UseWindowSize();
  const allPosts: any = [];
  for (const node of data.allSanityBlog.edges) {
    node.node["type"] = node.node.blogType[0].title;
    node.node["authors"] = node.node.author.name;
    node.node["filtertags"] = node.node.tags[0].title;
    node.node["detailtype"] = "blog post";
    if (node.node.tags[0].title === "DEUTSCH") {
      node.node["language"] = "🇩🇪";
    }else{
    node.node["language"] = "🇺🇸";
    }
    allPosts.push(node);
  }
  for (const node of data.allSanitySolutions.edges) {
    node.node["type"] = "solutions";
    node.node["detailtype"] = "solution";
    node.node["publishedAt"] = node.node._createdAt;
    node.node["language"] = "🇩🇪 | 🇺🇸";
    allPosts.push(node);
  }
  for (const node of data.allSanityCaseStudy.edges) {
    node.node["type"] = "case-study";
    node.node["detailtype"] = "case study";
    node.node["subtitle"] = node.node.caseStudyDescription[0].children[0].text;
    node.node["language"] = "🇩🇪 ⎮ 🇺🇸";
    allPosts.push(node);
  }
  for (const node of data.allSanityReport.edges) {
    node.node["type"] = "reports";
    node.node["detailtype"] = "report";
    node.node["language"] = "🇩🇪 | 🇺🇸";
    allPosts.push(node);
  }
  allPosts.sort(
    (
      a: { node: { publishedAt: string } },
      b: { node: { publishedAt: string } }
    ) => (a.node.publishedAt > b.node.publishedAt ? -1 : 1)
  );

  const handleInputChange = (event: { target: { value: any } }) => {
    const query = event.target.value;
    const filteredPosts = allPosts.filter(
      (post: {
        node: {
          subtitle: string;
          title: string;
          filtertags: string;
          detailtype: string;
          authors: string;
        };
      }) => {
        const { subtitle, title, filtertags, detailtype, authors } = post.node;
        return (
          (subtitle && subtitle.toLowerCase().includes(query.toLowerCase())) ||
          (title && title.toLowerCase().includes(query.toLowerCase())) ||
          (detailtype &&
            detailtype.toLowerCase().includes(query.toLowerCase())) ||
          (filtertags &&
            filtertags.toLowerCase().includes(query.toLowerCase())) ||
          (authors && authors.toLowerCase().includes(query.toLowerCase()))
        );
      }
    );
    setState({
      query,
      filteredPosts,
    });
  };

  const posts = state.query ? state.filteredPosts : undefined;
  const [showBlogImage, setShowBlogImage] = useState({
    image: "",
    pathToBlog: "",
    title: "",
    alt: "",
  });

  useEffect(() => {
    let firstTechBlogImage = data.allSanityBlog.edges.find(
      ({ node }: any) => node?.type
    );

    setShowBlogImage({
      image: firstTechBlogImage.node._rawMainImage,
      title: firstTechBlogImage.node.title || "",
      pathToBlog: "/" + firstTechBlogImage.node.type,
      alt: firstTechBlogImage.node.alt || "",
    });
  }, [data]);

  return (
    <div style={{ color: "white" }}>
      <Layout location={"/blog"}>
        <SEO
          title="Search"
          type="website"
          description="Explore the latest insights and success stories on our page by browsing through our engaging blog posts, in-depth case studies, cutting edge solutions, and comprehensive reports."
          image={tableDesktop}
        />
        <div className={styles.blogMainContainerWrapper}>
          <InnerWrapper>
            <div>
              <div className={styles.blogMainContainer}>
                <h1>
                  <span>.</span>
                  {translate("search-title")}
                </h1>
                <p>{translate("search-description")}</p>
                <div className={styles.backgroundCircles}>
                  <CircleOutline
                    isBlurVisible={false}
                    initialScale={0.3}
                    animateScale={1}
                    transitionType={"easeIn"}
                    transitionDelay={0.2}
                    transitionDuration={1}
                    circleStyleClass={styles.contactOutlineCircle}
                  />
                  <CircleGreenBlur
                    transitionType={"easeIn"}
                    transitionDelay={0.2}
                    transitionDuration={1}
                    circleStyleClass={styles.aboutMainCircleBlurGreen}
                  />

                  <CircleBlur
                    initialOpacity={0}
                    animateOpacity={0.1}
                    transitionType={"easeIn"}
                    transitionDelay={0.2}
                    transitionDuration={1}
                    circleStyleClass={styles.aboutMainCircleBlurBlue}
                  />
                </div>
              </div>
              <div style={{ width: "80%", marginLeft: "5%" }}>
                <div className={contactstyle.contactFormWrapper}>
                  <form style={{ width: "100%" }} onChange={handleInputChange}>
                    <div className={contactstyle.contactFormInputs}>
                      <div
                        className={
                          width > 576
                            ? contactinputstyle.field
                            : contactinputstyle.fieldMobile
                        }
                      >
                        <input
                          id="Search"
                          type="text"
                          placeholder="  "
                          value={state.query}
                          name="Search"
                          style={{ zIndex: 10 }}
                        />
                        <label htmlFor="Search">
                          {" "}
                          🔎 {translate("search-button")}{" "}
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className={styles.blogsMainArticles}>
                <div
                  className={styles.stickyContainerLeft}
                  id="stickyContainer"
                >
                  <Sticky boundaryElement="#stickyContainer">
                    {state.query ? (
                      <BlogArticleImage
                        rawImage={showBlogImage?.image}
                        pathToBlog={showBlogImage?.pathToBlog}
                        title={showBlogImage?.title}
                        imageClassName={styles.blogImageContainer}
                        imageName={"imagename"}
                        alt={showBlogImage?.alt}
                      />
                    ) : null}
                  </Sticky>
                </div>
                <div className={styles.blogsWrapper}>
                  {posts?.map(({ node }: any, index: number) => (
                    <BlogArticle
                      title={node.title}
                      description={node.subtitle}
                      key={index}
                      pic={node._rawMainImage}
                      dateBlog={node.publishedAt}
                      pathToBlog={"/" + node.type}
                      onBlogHover={setShowBlogImage}
                      alt={node?.alt}
                      type={node?.detailtype}
                      language={node?.language}
                    />
                  ))}
                </div>
              </div>

              <div className={styles.backgroundCirclesBlogArticles}>
                <CircleBlur
                  initialOpacity={0}
                  animateOpacity={0.1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.aboutMainCircleBlurGreen}
                />

                <CircleBlur
                  initialOpacity={0}
                  animateOpacity={0.1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.aboutMainCircleBlurBlue}
                />

                <CircleBlur
                  initialOpacity={0}
                  animateOpacity={0.1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.blogsCircleBlueRight}
                />
              </div>
            </div>
          </InnerWrapper>
        </div>
      </Layout>
    </div>
  );
};

export default Search;

export const pageQuery = graphql`
  query {
    allSanityBlog(sort: { order: DESC, fields: _createdAt }) {
      edges {
        node {
          id
          subtitle
          title
          blogType {
            title
          }
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          tags {
            title
          }
          author {
            name
          }
          authorTwo {
            name
          }
          alt
          publishedAt
        }
      }
    }
    allTags: allSanityTag(
        filter: { title: { ne: "DEUTSCH" } }
      ) {
        nodes {
          title
          _id
        }
      }
    allSanityReport(sort: { fields: _createdAt, order: DESC }) {
      edges {
        node {
          id
          title
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          subtitle
          publishedAt
        }
      }
    }
    allSanityCaseStudy(sort: { order: DESC, fields: _createdAt }) {
      edges {
        node {
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          id
          title
          altMainImage
          caseStudyDescription {
            children {
              text
            }
          }
          publishedAt
        }
      }
    }
    allSanitySolutions(sort: { order: DESC, fields: _createdAt }) {
      edges {
        node {
          id
          title
          alt
          subtitle
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          _createdAt
        }
      }
    }
  }
`;
