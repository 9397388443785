// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "ContactForm-module--aboutMainCircleBlurBlue--dfe1b";
export var aboutMainCircleBlurGreen = "ContactForm-module--aboutMainCircleBlurGreen--cad9c";
export var automate = "ContactForm-module--automate--c0e20";
export var backgroundCircles = "ContactForm-module--backgroundCircles--bedb9";
export var contactButton = "ContactForm-module--contactButton--749a6";
export var contactCheckBox = "ContactForm-module--contactCheckBox--ea481";
export var contactConfirmLabel = "ContactForm-module--contactConfirmLabel--dfb17";
export var contactCountry = "ContactForm-module--contactCountry--15d75";
export var contactEmail = "ContactForm-module--contactEmail--0e605";
export var contactFormAddress = "ContactForm-module--contactFormAddress--00453";
export var contactFormAddressDiv = "ContactForm-module--contactFormAddressDiv--5ba4b";
export var contactFormAddressItem = "ContactForm-module--contactFormAddressItem--e0af7";
export var contactFormAndAddressContainer = "ContactForm-module--contactFormAndAddressContainer--78848";
export var contactFormContainer = "ContactForm-module--contactFormContainer--3f343";
export var contactFormInputs = "ContactForm-module--contactFormInputs--dd868";
export var contactFormWrapper = "ContactForm-module--contactFormWrapper--d8938";
export var contactPhoneAndEmail = "ContactForm-module--contactPhoneAndEmail--3d431";
export var contactTabsCountry = "ContactForm-module--contactTabsCountry--c5b9f";
export var contactUs = "ContactForm-module--contactUs--49b4f";
export var contactUsForm = "ContactForm-module--contactUsForm--26332";
export var contactUsHeader = "ContactForm-module--contactUsHeader--f49da";
export var head = "ContactForm-module--head--9a9a0";
export var topBanner = "ContactForm-module--topBanner--0a3e5";